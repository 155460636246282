// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-cookies-policy-tsx": () => import("./../../../src/pages/legal/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-legal-cookies-policy-tsx" */),
  "component---src-pages-legal-general-risk-tsx": () => import("./../../../src/pages/legal/general-risk.tsx" /* webpackChunkName: "component---src-pages-legal-general-risk-tsx" */),
  "component---src-pages-legal-kyc-policy-tsx": () => import("./../../../src/pages/legal/kyc-policy.tsx" /* webpackChunkName: "component---src-pages-legal-kyc-policy-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-terms-conditions-tsx": () => import("./../../../src/pages/legal/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-legal-terms-conditions-tsx" */)
}

